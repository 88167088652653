<template>
  <div class="animate__animated animate__fadeIn">
    <top-bar>
      <div class="top-box">
        <img :src="logoImg" class="logo" />
        <div class="menus-box" :style="fontColor">
          <div
            class="menus"
            v-for="(m, mi) in menus"
            :style="active == m.path ? { fontWeight: 600 } : ''"
            :key="mi"
            @mouseover="changeOver(m)"
            :class="m.hover ? 'hover-bg' : ''"
            @mouseleave="mouseLeave(m)"
            @click="changePath(m)"
          >
            {{ m.name }}
            <div class="dt-line" v-if="active == m.path" :style="dtBg"></div>
            <div class="se-me hover-bg" v-if="m.hover">
              <div
                class="child"
                :style="fontColor"
                v-for="(c, ci) in m.child"
                :key="ci"
                @click.stop="changePath(c)"
              >
                {{ c.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </top-bar>
    <div class="project-box">
      <div class="title">最新动态</div>
      <div class="tb">News</div>
    </div>
    <div class="de-box">
      <div class="de" v-for="(b, bi) in book" :key="bi">
        <img :src="b.img" class="de-img" />
        <div class="msg">
          <div class="msg-top">
            <div class="m-box">
              <span class="t">{{ b.time }}</span>
              <span class="date">{{ b.date }}</span>
            </div>
            <div class="title">{{ b.title }}</div>
          </div>
          <div class="content">
            {{ b.content }}
          </div>
          <button class="btn" @click="goInfo(b)">
            查看详情
            <img src="../static/home/youjiantou.png" class="btn-img" />
          </button>
        </div>
      </div>
    </div>
    <!-- <div class="more-box">
			<button >查看更多</button>
		</div> -->
    <div style="width: 100%; margin-top: 251px">
      <footer-bar path="Dynamic"></footer-bar>
    </div>
  </div>
</template>

<script>
import topBar from "@/components/header-bar.vue";
import utils from "@/utils/common.js";
import footerBar from "@/components/footer-bar.vue";
export default {
  components: {
    topBar,
    footerBar,
  },
  data() {
    return {
      name: "Dynamic",
      menus: utils.menus,
      active: "Dynamic",
      swiperIndex: 1,
      book: [
        {
          img: require("../static/project/w10.png"),
          date: "2023/05",
          time: "24",
          type: 10,
          title: "利用二氧化碳生产混凝土，「清捕零碳」完成千万元天使+轮融资",
          content:
            "「清捕零碳」完成千万元天使+轮融资，由元禾原点、住友商事亚洲资本及其他产业方投资人出资。「清捕零碳」创始人赵超告诉36碳，住友商事作为日本最大的混凝土交易商之一，未来将积极推进与住友商事在中国及海外地区的项目合作，本轮融资将用于持续落地二氧化碳矿化产线的建设规划及技术研发。",
        },
        {
          img: require("../static/project/w9.png"),
          date: "2023/05",
          time: "10",
          type: 9,
          title: "清捕零碳与恒隆签订战略合作 推动建筑行业减少隐含碳排放",
          content:
            "近日，专注碳捕集利用与封存（以下简称〝CCUS〞）的技术企业清捕零碳与恒隆地产（“公司”或“恒隆”）宣布进行为期两年的战略合作（〝合作〞），双方将在二氧化碳矿化强化废弃混凝土低碳再生骨料（以下简称〝低碳RCA〞）技术研发及“清洁捕获固碳混凝土实心砖”（“固碳混凝土实心砖”）商业项目应用等层面开展深度合作，减少杭州恒隆广场和其他商业项目中的隐含碳排放。杭州恒隆广场将是内地和香港首个使用固碳混凝土实心砖的商业发展项目。",
        },
        {
          img: require("../static/project/w8.png"),
          date: "2023/02",
          time: "13",
          type: 8,
          title: "清捕零碳CCUS固碳混凝土技术 入选《 Impact 50 可持续创新先锋案例》",
          content:
            "近日，长期关注全球可持续发展创新变革的Makeable 可持续创新观察和 Impact Hub Shanghai正式发布《2022 Impact 50 可持续创新先锋案例集》（以下简称《Impact 50》）。此次评选基于全球发展趋势，聚焦零碳转型、循环经济、可持续消费与供应链、包容性创新四大议题18个领域，清捕零碳凭借在CCUS领域的技术优势及商业化模式，从全球471个创新案例中脱颖而出，为通过CCUS技术实现全球大幅减排提供了变革性的创新方向。",
        },
        {
          img: require("../static/project/w7.png"),
          date: "2023/02",
          time: "08",
          type: 7,
          title: "清捕零碳与中建香港签署合作协议 固碳混凝土建材加速湾区建筑低碳发展",
          content:
            "2023年1月16日，清捕零碳（北京）科技有限公司（以下简称清捕零碳）与中国建筑工程（香港）有限公司（以下简称中建香港）旗下公司正式签订合作协议。未来5年的时间里，双方将依托于清捕零碳研发的二氧化碳矿化混凝土建材技术，以及中建香港在港澳地区建筑行业的深耕经验，在港澳地区推广应用具有固碳属性的混凝土建材及技术，并以此为基础深化合作，探索建筑行业的绿色低碳发展新路径。",
        },
        {
          img: require("../static/project/w6.png"),
          date: "2023/01",
          time: "12",
          type: 6,
          title: "清捕零碳公司运用CCUS技术 获国内首张固碳混凝土建材碳足迹认证证书",
          content:
            "近日， 国内首个通过CCUS（二氧化碳捕集、利用与封存）生产的具有“吸碳”功能的混凝土建材，获得了权威检测机构颁发的碳足迹认证证书。该张碳足迹认证证书也标志着国内混凝土建材行业正式开始借助碳捕集利用技术实现减碳。根据测算，“清洁捕获固碳实心砖”与传统产品相比可减少87%的碳足迹。",
        },
        {
          img: require("../static/project/w5.png"),
          date: "2022/02",
          time: "07",
          type: 5,
          title: "「清洁捕获」完成数百万天使轮融资，想把二氧化碳封存在混凝土里",
          content:
            "近日，「清洁捕获」完成险峰资本投资的数百万元，资金将用于早期团队建设。「清洁捕获」成立于2021年，主要的研发方向是将捕集而来的二氧化碳用于制造低碳混凝土材料，实现废弃二氧化碳的高效封存，以此来恢复地球的碳平衡。二氧化碳作为温室气体致使全球变暖，带来了生态环境破坏等一系列问题，二氧化碳减排已成为当今国际社会关注的热点。利用二氧化碳捕集、利用和封存（CCUS）技术可以直接把二氧化碳“捉住”，可以大幅减少二氧化碳的排放，再将二氧化碳资源化利用，可以实现变废为宝。",
        },
		{
          img: require("../static/project/w4.png"),
          date: "2021/09",
          time: "28",
          type: 4,
          title: "清洁捕获入驻国家电投清洁能源融通创新发展平台",
          content:
            "2021年9月28日上午，清洁捕获正式入驻在国家电投清洁能源融通创新发展平台，清洁捕获联合创始人赵超作为入驻企业代表参加了在北京昌平未来科学城国家电投集团中央研究院园区举办的平台揭牌仪式。在入驻会议上，清洁捕获与国家电投清洁能源融通创新发展平台签署入驻协议。未来双方将在二氧化碳捕集、利用等方面，开展联合攻关、联合研发、成果转化的落地等相关工作，共同助力国家清洁能源事业发展，促进科技创新、推动产业发展。",
        },
        {
          img: require("../static/project/w3.png"),
          date: "2021/09",
          time: "24",
          type: 3,
          title: "国际气候峰会｜清洁捕获分享CO₂制造低碳混凝土前沿技术",
          content:
            "为积极推动国家碳中和愿景和全球控温目标实现，凤凰卫视与世界自然基金会（WWF）于2021年9月22日-24日主办2021零碳使命国际气候峰会，主题为“全球碳中和与中国担当”。联合主办本次峰会的有凤凰网、凤凰都市传媒、北京市企业家环保基金会（SEE基金会）、大道应对气候变化促进中心（C Team）、公众环境研究中心（IPE），英国以COP26联合国气候大会主席国身份参与。在本次峰会活动中，清洁捕获联合创始人赵超受邀参加国际气候峰会“C 位青年 x 凤凰卫视世纪大讲堂 ”。",
        },
        {
          img: require("../static/project/w1.png"),
          date: "2021/07",
          time: "30",
          type: 2,
          title: "浙大教授王涛：自主研发抢跑CCUS科技创新让二氧化碳变废为宝",
          content:
            "根据生态环境部测算，全国碳交易市场首批纳入的企业的碳排放量超过40亿吨二氧化碳。2011年10月，国家发改委发布《关于开展碳排放交易试点工作的通知》，批准了北京、天津、上海等7个省市开展碳排放权交易试点。我国碳市场由此开始建设，释放出“绿色低碳即竞争力”这一信号。浙江大学能源工程学院王涛教授认为，在体量上，碳排放可谓人类历史上碰到的最大的废弃物处理问题。“通过技术和管理手段的创新，大气中的废碳，也能像垃圾一样得到消纳，甚至变废为宝，实现环境保护。”他说。",
        },
        {
          img: require("../static/project/w2.png"),
          date: "2021/07",
          time: "28",
          type: 1,
          title: "清洁捕获研发能“吃”CO₂的混凝土  让98% 废弃CO₂被有效利用",
          content:
            "近日，SDGs创业挑战赛举办了第四期“赋能后浪与零碳社区”论坛。作为一家专注利用人工技术清除CO₂的创新企业，清洁捕获联合创始人赵超受邀参与此次论坛的分享。据赵超介绍清洁捕获公司通过对碳捕捉、利用与封存（CCUS）技术的研发，已成功将捕集的CO₂封存在混凝土材料中，生产出减少80%碳足迹的低碳混凝土产品。现场，与会嘉宾们还共同探讨了低碳混凝土在建筑行业能够广泛应用的前景及发展规划。",
        },
      ],
    };
  },
  computed: {
    logoImg() {
      // console.log(this.swiperIndex,'lll')
      if (this.swiperIndex == 0) {
        return require("../static/qlogo.png");
      } else if (this.swiperIndex == 1) {
        return require("../static/qlogo.png");
      } else if (this.swiperIndex == 2) {
        return require("../static/wlogo.png");
      } else if (this.swiperIndex == 3) {
        return require("../static/home/logo2.png");
      } else if (this.swiperIndex == 4) {
        return require("../static/home/logo2.png");
      } else if (this.swiperIndex == 5) {
        return require("../static/wlogo.png");
      } else if (this.swiperIndex == 6) {
        return require("../static/home/logo2.png");
      }
    },
    fontColor() {
      if (this.swiperIndex == 0) {
        return {
          color: "#fff",
        };
      } else if (this.swiperIndex == 1) {
        return {
          color: "#333",
        };
      } else if (this.swiperIndex == 2) {
        return {
          color: "#fff",
        };
      } else if (this.swiperIndex == 3) {
        return {
          color: "#333",
        };
      } else if (this.swiperIndex == 4) {
        return {
          color: "#333",
        };
      } else if (this.swiperIndex == 5) {
        return {
          color: "#fff",
        };
      } else if (this.swiperIndex == 6) {
        return {
          color: "#333",
        };
      }
    },
    dtBg() {
      if (this.swiperIndex == 0) {
        return {
          background: "#fff",
        };
      } else if (this.swiperIndex == 1) {
        return {
          background: "#333",
        };
      } else if (this.swiperIndex == 2) {
        return {
          background: "#fff",
        };
      } else if (this.swiperIndex == 3) {
        return {
          background: "#333",
        };
      } else if (this.swiperIndex == 4) {
        return {
          background: "#333",
        };
      } else if (this.swiperIndex == 5) {
        return {
          background: "#fff",
        };
      } else if (this.swiperIndex == 6) {
        return {
          background: "#333",
        };
      }
    },
  },
  methods: {
    changeOver(m) {
      // console.log(m)
      if (m.path == "solution") {
        m.hover = true;
        this.active = m.path;
      }
    },
    mouseLeave(m) {
      this.menus.forEach((item) => (item.hover = false));
      this.active = this.name;
    },
    changePath(item) {
      if (item.path != this.name && item.path != "solution") {
        this.$router.push({
          name: item.path,
        });
      }
    },
    goInfo(b) {
      this.$router.push({
        name: "DynamicInfo",
        query: {
          type: b.type,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  // background: #eeeeee;
  margin: 0;
  padding: 0;
}
.active-h {
  font-weight: 600;
}
.top-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // background: #007AFF;
  .logo {
    width: 42px;
    height: 47px;
    margin-left: 11.25rem;
  }
  .menus-box {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 197px;
    position: relative;
  }
  .menus {
    height: 100%;
    width: 114px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // margin-right: 60px;
    font-size: 14px;
    font-weight: 400;

    cursor: pointer;
    box-sizing: border-box;
    position: relative;
    .dt-line {
      width: 20px;
      height: 4px;
      // background: #FFFFFF;
      position: absolute;
      bottom: 16px;
    }
    .se-me {
      position: absolute;
      width: 114px;
      height: 108px;
      bottom: -108px;
    }
    .child {
      width: 100%;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 400;
      // color: #FFFFFF;
    }
  }
}

.project-box {
  width: 100%;
  height: 400px;
  // background: rgba(0, 0, 0, 0.5);
  background-image: url(../static/project/dongtai.png);
  position: relative;
  background-size: 100% 100%;
  padding-top: 182px;
  box-sizing: border-box;
  .bg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }
  .title {
    font-size: 48px;
    color: #1a1a1a;
    margin-left: 175px;
  }
  .tb {
    font-size: 28px;
    font-weight: 400;
    color: #666666;
    margin-top: 12px;
    margin-left: 175px;
  }
}

.t-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 80px;
  .c-box {
    width: 500px;
    height: 58px;
    font-size: 58px;
    font-weight: bold;
    color: #f7f7f7;
    position: relative;
    .c-ti {
      font-size: 32px;
      font-weight: 600;
      color: #1a1a1a;
      position: absolute;
      bottom: 0;
      left: 35%;
    }
  }
}

.de-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 102px;
  .de {
    width: 1079px;
    height: 321px;
    display: flex;
    // align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
    .de-img {
      width: 501px;
      height: 321px;
    }
    .msg {
      width: 100%;
      height: 100%;
      padding: 0 0 0 40px;
      box-sizing: border-box;
      .msg-top {
        width: 100%;
        display: flex;
        align-items: center;
        .m-box {
          width: 74px;
          height: 70px;
          background: #ffffff;
          border: 2px solid #cccccc;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin-right: 24px;
          padding: 0 8px;
          box-sizing: border-box;
          .t {
            font-size: 24px;
            font-weight: 500;
            color: #1a1a1a;
          }
          .date {
            font-size: 16px;
            font-weight: 500;
            color: #1a1a1a;
          }
        }
        .title {
          font-size: 24px;
          font-weight: 500;
          color: #1a1a1a;
        }
      }
    }
  }
  .content {
    width: 540px;
    height: 112px;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    margin-top: 31px;
  }
  .btn {
    width: 134px;
    height: 50px;
    background: #075ad3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    margin-top: 55px;
    margin-left: auto;
    cursor: pointer;
    img {
      width: 18px;
      height: 18px;
      margin-left: 6px;
    }
  }
}

.more-box {
  width: 100%;
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    width: 200px;
    height: 70px;
    background: #ffffff;
    border: 2px solid #e6e6e6;
    padding: 0;
    margin: 0;
    line-height: 70px;
    font-size: 24px;
    font-weight: 400;
    color: #4d4d4d;
    cursor: pointer;
  }
}
</style>
